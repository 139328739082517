import {GuideFilter} from "../filters/GuideFilter";
import {ProductionStage} from "../production-stage/ProductionStage";
import {WorkOrderInfo} from "../work-order-info/WorkOrderInfo";

export interface ProductionOrder {
    documentNumber: number,
    workOrderInfo: WorkOrderInfo,
    priority: number | null,
    productionLine: ProductionLine | null,
    mainProduct: ProductionOrderItem | null,
    additionalEquipment: ProductionOrderItem[],
    status: ProductionOrderStatus,
    progress: ProductionStageProgress[]
    productionComment: string,
    creationTime: string,
    addedInLineTime: string,
    productionStartTime: string,
    planningCompletionDate: Date,
    completionTime: string | null
}

export interface UpdateOrCreateProductionOrderRequest {
    priority: number | null,
    productionLineId: number,
    numberFromWork: number,
    targetItem: ProductionOrderItemForRequest,
    additionalItems: ProductionOrderItemForRequest[],
    productionComment: string,
    planningCompletionDate: Date
}

export interface ProductionOrderItemForRequest {
    nomenclatureId: number;
    quantity: string;
    technologicalProcessChartId: number | null
}

export interface ProductionOrderItem {
    nomenclature: NomenclatureInfo,
    number: number,
    technologicalProcessChart: {
        id: number,
        nomenclature: NomenclatureInfo,
        quantity: string
    },
    quantity: string
}

export interface ProductionOrderJournalRow {
    documentNumber: number,
    workOrderNumber: number,
    priority: number | null,
    productionLine: ProductionLine,
    mainProduct: MainProductOrAdditionalEquipmentInterface,
    additionalEquipment: MainProductOrAdditionalEquipmentInterface[],
    status: ProductionOrderStatus,
    progress: ProductionStageProgress[]
    planningCompletionDate: string,
    completionTime: string | null
}

export interface ProductionStageProgress {
    productionStage: ProductionStage,
    percentageProgress: number,
    quantityProgress: QuantityProgress,
    hasPostponedTask: boolean,
    timeSpent?: string,
}

export interface QuantityProgress {
    planningQuantity: number,
    actualQuantity: number
}

export interface ProductionLine {
    id: number,
    name: string
}

export interface MainProductOrAdditionalEquipmentInterface {
    nomenclature: NomenclatureInfo,
    number: number,
    technologicalProcessChartId: number,
    quantity: string
}

export interface NomenclatureInfo {
    id: number,
    name: string,
    measurementUnit: {
        id: number,
        name: string
    },
    mainSKU: string | null,
    drawingNumber: string | null
}

export enum ProductionOrderStatus {
    WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART = "WAITING_SELECTION_TECHNOLOGICAL_PROCESS_CHART",
    IN_LINE	= "IN_LINE",
    IN_PRODUCTION = "IN_PRODUCTION",
    COMPLETED = "COMPLETED"
}

export interface ProductionOrderJournalFilter extends GuideFilter{
    status: ProductionOrderStatus[],
    isArchived?: boolean
}