import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserGuideFilter} from "../../model/users/UserGuideFilter";
import {GuideFilter} from "../../model/filters/GuideFilter";
import {
    DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
    DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER,
    DEFAULT_INDIVIDUALS_GUIDE_FILTER,
    DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER,
    DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER,
    DEFAULT_NOMENCLATURE_GUIDE_FILTER,
    DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER,
    DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
    DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER,
    DEFAULT_PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER,
    DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER,
    DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
    DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
    DEFAULT_USER_GUIDE_FILTER
} from "../../model/filters/DefaultFilters";
import {NomenclatureGuideFilter} from "../../model/nomenclature/NomenclatureGuideFilter";
import {TableFilterKeys, TableFilterType} from "../../model/filters/TableFiltersDefinitions";
import {ProductionOrderExecutionJournalFilter} from "../../model/production-order-execution/ProductionOrderExecution";
import {IndividualsGuideFilter} from "../../model/individuals/Individuals";
import {TechnologicalProcessChartGuideFilter} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {AdministrativeRoleGuideFilter} from "../../model/administrative-roles/AdministrativeRole";
import {ProductionStageGuideFilter} from "../../model/production-stage/ProductionStage";
import {TechnologicalOperationGuideFilter} from "../../model/technological-operation/TechnologicalOperationGuideFilter";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {ProductionOrderJournalFilter} from "../../model/production-order/ProductionOrder";
import {MeasurementGuideFilter} from "../../model/measurement/MeasurementGuideFilter";
import {clearData} from "./data.slice";
import {MeasurementOkeiGuideFilter} from "../../model/measurement/MeasurementOkeiGuideFilter";
import {
    ProductionOrderAnalysisByTimeReportFilter
} from "../../model/production-order-analysis-by-time/ProductionOrderAnalysisByTime";

export interface UpdateFilter {
    key: TableFilterKeys,
    value: TableFilterType,
}
export interface UpdateFilterPage {
    key: TableFilterKeys,
    value: number,
}

interface TableFilter<T extends GuideFilter> {
    filter: T,
    haveAdditionalFiltersMenu: boolean,
    isMenuVisible: boolean,
    filterDefault: T,
}
export interface InitialFiltersInterface {
    [TableFilterKeys.USER_GUIDE_FILTER]: TableFilter<UserGuideFilter>,
    [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: TableFilter<NomenclatureGuideFilter>,
    [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: TableFilter<ProductionOrderExecutionJournalFilter>
    [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: TableFilter<IndividualsGuideFilter>,

    [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: TableFilter<AdministrativeRoleGuideFilter>,
    [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: TableFilter<TechnologicalProcessChartGuideFilter>,
    [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: TableFilter<ProductionStageGuideFilter>,
    [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: TableFilter<TechnologicalOperationGuideFilter>,
    [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: TableFilter<AdministrativeAndFunctionalZoneGuideFilter>,
    [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: TableFilter<ProductionOrderJournalFilter>,
    [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: TableFilter<ProductionOrderJournalFilter>,
    [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: TableFilter<ProductionOrderAnalysisByTimeReportFilter>,
    [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: TableFilter<MeasurementGuideFilter>,
    [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: TableFilter<MeasurementOkeiGuideFilter>
}

interface InitialStateInterface {
    filters: InitialFiltersInterface
}

const initialState: InitialStateInterface = {
    filters: {
        [TableFilterKeys.USER_GUIDE_FILTER]: {
            filter: DEFAULT_USER_GUIDE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_USER_GUIDE_FILTER,
        },
        [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: {
            filter: DEFAULT_NOMENCLATURE_GUIDE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_NOMENCLATURE_GUIDE_FILTER,
        },
        [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: {
            filter: DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER,
        },
        [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: {
            filter: DEFAULT_INDIVIDUALS_GUIDE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_INDIVIDUALS_GUIDE_FILTER,
        },
        [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: {
            filter: DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER,
            haveAdditionalFiltersMenu: false,
            isMenuVisible: false,
            filterDefault: DEFAULT_ADMINISTRATIVE_ROLE_GUIDE_FILTER,
        },
        [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: {
            filter: DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER,
        },
        [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: {
            filter: DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER,
            haveAdditionalFiltersMenu: false,
            isMenuVisible: false,
            filterDefault: DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER,
        },
        [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: {
            filter: DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
            haveAdditionalFiltersMenu: false,
            isMenuVisible: false,
            filterDefault: DEFAULT_TECHNOLOGICAL_OPERATION_GUIDE_FILTER,
        },
        [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: {
            filter: DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER,
        },
        [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: {
            filter: DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_PRODUCTION_ORDER_JOURNAL_FILTER,
        },
        [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: {
            filter: DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_PRODUCTION_ORDER_ARCHIVE_FILTER,
        },
        [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: {
            filter: DEFAULT_PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER,
            haveAdditionalFiltersMenu: true,
            isMenuVisible: false,
            filterDefault: DEFAULT_PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER,
        },
        [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: {
            filter: DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER,
            haveAdditionalFiltersMenu: false,
            isMenuVisible: false,
            filterDefault: DEFAULT_MEASUREMENT_UNIT_GUIDE_FILTER,
        },
        [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: {
            filter: DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER,
            haveAdditionalFiltersMenu: false,
            isMenuVisible: false,
            filterDefault: DEFAULT_MEASUREMENT_OKEI_GUIDE_FILTER,
        }
    }
};
export const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        toggleFiltersVisibility: (state, action: PayloadAction<TableFilterKeys>) => {
            state.filters[action.payload].isMenuVisible = !state.filters[action.payload].isMenuVisible
        },
        updateFilter: (state: InitialStateInterface, action: PayloadAction<UpdateFilter>) => {
            if (state.filters[action.payload.key]) {
                state.filters[action.payload.key].filter = {...state.filters[action.payload.key].filter, ...action.payload.value}
            }
        },
        updateFilterPage: (state: InitialStateInterface, action: PayloadAction<UpdateFilterPage>) => {
            if (state.filters[action.payload.key]) {
                state.filters[action.payload.key].filter = {...state.filters[action.payload.key].filter, pageNumber: action.payload.value}
            }
        },
        resetFilterToDefault: (state, action: PayloadAction<TableFilterKeys>) => {
            if (state.filters[action.payload]) {
                state.filters[action.payload].filter = state.filters[action.payload].filterDefault
            }
        },
    }
})

export const {
    toggleFiltersVisibility,
    updateFilter,
    updateFilterPage,
    resetFilterToDefault,
} = filtersSlice.actions;

export const filtersActions = {
    toggleFiltersVisibility,
    updateFilterPage,
};

export const filtersReducer = filtersSlice.reducer

export const updateFilterAndClearDataAction = (payload: UpdateFilter) => (dispatch: any) => {
    dispatch(clearData(payload.key))
    dispatch(updateFilter(payload))
}
export const resetFilterToDefaultAndClearDataAction = (payload: TableFilterKeys) => (dispatch: any) => {
    dispatch(clearData(payload))
    dispatch(resetFilterToDefault(payload))
}