import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";

interface InitialStateInterface {
    filters: FilterForMenu[],
    isExist: boolean,
    isVisible: boolean,
    key: string,
    [TableFilterKeys.USER_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: FilterForMenu[],
    [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: FilterForMenu[],

    [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: FilterForMenu[],
    [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: FilterForMenu[],
    [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: FilterForMenu[],
    [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: FilterForMenu[],
    [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: FilterForMenu[]
}

export interface FilterForMenu{
    filterName: string,
    filter: ReactNode
}
interface AddFiltersToMenu {
    key: TableFilterKeys;
    value: FilterForMenu[],
}

const initialState: InitialStateInterface = {
    filters: [],
    isExist: false,
    isVisible: false,
    key: "-",
    [TableFilterKeys.USER_GUIDE_FILTER]: [],
    [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: [],
    [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: [],
    [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: [],

    [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: [],
    [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: [],
    [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: [],
    [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: [],
    [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: [],
    [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: [],
    [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: [],
    [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: [],
    [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: [],
    [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: []
}
export const filterNodesSlice = createSlice({
    name: "filters-for-menu-filters",
    initialState,
    reducers: {
        addFiltersToMenu: (state, action : PayloadAction<AddFiltersToMenu>) => {
            if (action.payload.key) {
                state[action.payload.key] = action.payload.value
            }
        },
        oldAddToMenuFilters: (state, action: PayloadAction<{ filterName: string, filter: ReactNode }[]>) => {
            state.filters = action.payload
        },
        changeMenuVisibility: (state, action: PayloadAction<boolean | undefined>) => {
            if (action.payload !== undefined) {
                state.isVisible = action.payload
            } else {
                state.isVisible = !state.isVisible
            }
        },
        changeMenuExistence: (state, action: PayloadAction<boolean>) => {
            state.isExist = action.payload
        },
        setKey: (state, action: PayloadAction<string>) => {
            state.key = action.payload
        }
    }
})

export const filterNodesActions = filterNodesSlice.actions
export const filterNodesReducer = filterNodesSlice.reducer