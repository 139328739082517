import React from "react";
import {MainProductOrAdditionalEquipmentInterface} from "../../../model/production-order/ProductionOrder";
import {TablePage} from "../../../model/utils/TablePage";
import {TechnologicalProcessChart} from "../../../model/technological-process-chart/TechnologicalProcessChart";
import {Control} from "react-hook-form";
import {ProductionOrderFormFields} from "./ProductionOrderFormTemplate";
import {
    useLazySearchNomenclatureGuidePageQuery,
    useLazySearchTechnologicalProcessChartGuidePageQuery
} from "../../../store/backend-api/backend.api";
import {
    ControlledPaginateAsyncSelectForTable
} from "../../ui/form-components/controlled/ControlledPaginateAsyncSelectForTable";
import {ControlledInputFieldForTable} from "../../ui/form-components/controlled/ControlledInputFieldForTable";
import {NomenclatureGuideRow} from "../../../model/nomenclature/NomenclatureGuideRow";

interface TableProps {
    control: Control<ProductionOrderFormFields>,
    readonly?: boolean
}

export function TableForMainProduct(props: TableProps) {
    const [doSearchNomenclatureGuidePage] = useLazySearchNomenclatureGuidePageQuery()
    const [doSearchTechnologicalProcessChartGuidePage] = useLazySearchTechnologicalProcessChartGuidePageQuery()

    return (
        <table>
            <thead>
            <tr>
                <th className="border border-black text-base font-normal text-center px-2">№</th>
                <th className="border border-black text-base font-normal text-center px-2">Наименование</th>
                <th className="border border-black text-base font-normal text-center px-2">Количество</th>
                <th className="border border-black text-base font-normal text-center px-2">Технологическая карта</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className="border border-black text-base font-light px-2">1</td>
                <td className="border border-black text-base font-light px-2">
                    <ControlledPaginateAsyncSelectForTable control={props.control}
                                                           rules={{
                                                               required: {
                                                                   value: true,
                                                                   message: "Это обязательное поле"
                                                               }
                                                           }}
                                                           name={"nomenclature"}
                                                           request={doSearchNomenclatureGuidePage}
                                                           mapFunc={(data: TablePage<NomenclatureGuideRow>) =>
                                                               data.rows.map(nomenclature =>
                                                                   ({
                                                                       label: `${nomenclature.name}, ${nomenclature.mainSKU || "–"}`,
                                                                       value: nomenclature.id
                                                                   }))}
                                                           isDisabled={props.readonly}/>
                </td>
                <td className="border border-black text-base font-light px-2">
                    <ControlledInputFieldForTable control={props.control}
                                                  rules={{required: {value: true, message: "Это обязательное поле"}, validate: (value: number) => value > 0 || 'Количество должно быть больше нуля'}}
                                                  name={'quantity'} readonly={props.readonly} type='number'/>
                </td>
                <td className="border border-black text-base font-light px-2">
                    <ControlledPaginateAsyncSelectForTable control={props.control}
                                                           rules={{}}
                                                           name={"technologicalProcessChart"}
                                                           request={doSearchTechnologicalProcessChartGuidePage}
                                                           mapFunc={(data: TablePage<TechnologicalProcessChart>) =>
                                                               data.rows.map(technologicalProcessChart =>
                                                                   ({
                                                                       label: `${technologicalProcessChart.targetProductName}, ${technologicalProcessChart.targetProductMainSKU || "–"}`,
                                                                       value: technologicalProcessChart.id
                                                                   }))}
                                                           isDisabled={props.readonly}/>
                </td>
            </tr>
            </tbody>
        </table>
    )
}