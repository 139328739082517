import {createApi} from "@reduxjs/toolkit/query/react";
import {NomenclatureGuideFilter} from "../../model/nomenclature/NomenclatureGuideFilter";
import {NomenclatureAttachment, NomenclatureForm} from "../../model/nomenclature/NomenclatureForm";
import {MeasurementUnit, UpdateOrCreateMeasurementUnit} from "../../model/measurement/MeasurementUnit";
import {NomenclatureGuideRow} from "../../model/nomenclature/NomenclatureGuideRow";
import {UserGuideFilter} from "../../model/users/UserGuideFilter";
import {UserGuideRow} from "../../model/users/UserGuideRow";
import {CreatingOrUpdatingUser, User} from "../../model/users/User";
import {MeasurementGuideFilter} from "../../model/measurement/MeasurementGuideFilter";
import {TablePage} from "../../model/utils/TablePage";
import {TechnologicalOperationGuideRow} from "../../model/technological-operation/TechnologicalOperationGuideRow";
import {GuideFilter} from "../../model/filters/GuideFilter";
import {
    CreateOrUpdateTechnologicalOperation,
    TechnologicalOperation
} from "../../model/technological-operation/TechnologicalOperation";
import {TechnologicalOperationGuideFilter} from "../../model/technological-operation/TechnologicalOperationGuideFilter";
import {MeasurementOkei} from "../../model/measurement/MeasurementOkei";
import {MeasurementOkeiGuideFilter} from "../../model/measurement/MeasurementOkeiGuideFilter";
import {
    AdministrativeAndFunctionalZoneGuideRow
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideRow";
import {
    AdministrativeAndFunctionalZoneGuideFilter
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuideFilter";
import {
    AdministrativeAndFunctionalZone,
    CreateOrUpdateAdministrativeAndFunctionalZone
} from "../../model/administrative-and-functional-zone/AdministrativeAndFunctionalZone";
import {
    CreateOrCopyBillOfMaterialsRequest,
    CreateStepOfBillOfMaterialsRequest,
    EditMaterialOfBillOfMaterialsRequest,
    EditProductOfBillOfMaterialsRequest,
    EditStageOfBillOfMaterialsRequest,
    Material,
    Product,
    Stage,
    Step,
    TechnologicalProcessChart,
    TechnologicalProcessChartGuideFilter
} from "../../model/technological-process-chart/TechnologicalProcessChart";
import {
    AdministrativeRole,
    AdministrativeRoleGuideFilter,
    AdministrativeRolesRow,
    CreatingOrUpdatingAdministrativeRole
} from "../../model/administrative-roles/AdministrativeRole";
import {
    CreatingOrUpdatingProductionStage,
    ProductionStage,
    ProductionStageGuideFilter
} from "../../model/production-stage/ProductionStage";
import {
    ProductionOrder,
    ProductionOrderJournalFilter,
    ProductionOrderJournalRow,
    ProductionOrderStatus,
    UpdateOrCreateProductionOrderRequest
} from "../../model/production-order/ProductionOrder";
import {
    PositionsForProductionOrderExecution,
    ProductionOrderExecutionJournalFilter,
    ProductionOrderExecutionJournalRow,
    ProductionTaskAttachment,
    ProductionTaskStatus,
    TasksForProductionOrderExecution
} from "../../model/production-order-execution/ProductionOrderExecution";
import {Tokens, UsernamePassword} from "../../model/security/Tokens";
import {
    CreateOrUpdateIndividuals,
    Individuals,
    IndividualsGuideFilter,
    IndividualsRow
} from "../../model/individuals/Individuals";
import {baseQueryWithReauth} from "./BaseQueryWithReauth";
import {UserCard} from "../../model/security/UserCard";
import {UpdateUserProfile} from "../../model/security/UserProfile";
import {
    ProductionOrderAnalysisByTime,
    ProductionOrderAnalysisByTimeReportFilter
} from "../../model/production-order-analysis-by-time/ProductionOrderAnalysisByTime";

export function defBaseUrl() {
    const baseUrlFromEnv = process.env.REACT_APP_BASE_URL;

    if (baseUrlFromEnv)
        return baseUrlFromEnv

    if (process.env.NODE_ENV === 'production')
        return 'https://api.asup.uzvo.ru'
    else
        return 'https://api.asup.uzvo.ru'
}

function paramsForIndividualsQuery(filter: IndividualsGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.gender) {
        params["gender"] = filter.gender
    }
    if (filter.isUser) {
        params["is-user"] = filter.isUser
    }
    if (filter.sortedField) {
        params["sorted-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    return params
}

function paramsForProductionOrderExecutionQuery(filter: ProductionOrderExecutionJournalFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.productionLineId) {
        params["production-line-id"] = filter.productionLineId
    }
    return params
}

function paramsForNomenclatureQuery(filter: NomenclatureGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.productionNomenclatureClassifier !== null) {
        params["prod-classifier"] = filter.productionNomenclatureClassifier
    }
    if (filter.sortedField) {
        params["sort-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    return params
}

function paramsForUsersQuery(filter: UserGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search !== null && filter.search.length) {
        params["search"] = filter.search
    }
    if (filter.status) {
        params["status"] = filter.status
    }
    if (filter.role && filter.role.length) {
        params["role"] = filter.role
    }
    if (filter.lastActivityFrom) {
        params["last-activity-from"] = filter.lastActivityFrom
    }
    if (filter.lastActivityTo) {
        params["last-activity-to"] = filter.lastActivityTo
    }
    if (filter.registrationFrom) {
        params["registration-from"] = filter.registrationFrom
    }
    if (filter.registrationTo) {
        params["registration-to"] = filter.registrationTo
    }
    if (filter.sortedField) {
        params["sorted-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    return params
}

function paramsForTechnologicalProcessChartQuery(filter: TechnologicalProcessChartGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.productionNomenclatureClassifier !== null) {
        params["prod-classifier"] = filter.productionNomenclatureClassifier
    }
    return params
}

function paramsForProductionOrderQuery(filter: ProductionOrderJournalFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.status !== null) {
        params["status"] = filter.status
    }
    if (filter.isArchived) {
        params["is-archived"] = filter.isArchived
    }
    return params
}

function paramsForAdministrativeRolesQuery(filter: AdministrativeRoleGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    return params
}

function paramsForProductionStagesQuery(filter: ProductionStageGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    return params
}

function paramsForMeasurementUnitOrOkeiQuery(filter: MeasurementGuideFilter | MeasurementOkeiGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.sortedField) {
        params["sorted-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    return params
}

function paramsForAdministrativeAndFunctionalZoneQuery(filter: AdministrativeAndFunctionalZoneGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.sortedField) {
        params["sorted-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    if (filter.purpose) {
        params["purpose"] = filter.purpose
    }
    return params
}

function paramsForTechnologicalOperationQuery(filter: TechnologicalOperationGuideFilter) {
    let params: any = {}
    params["p-num"] = filter.pageNumber
    params["p-size"] = filter.pageSize
    if (filter.search) {
        params["search"] = filter.search
    }
    if (filter.sortedField) {
        params["sorted-field"] = filter.sortedField
    }
    if (filter.sortOrder) {
        params["sort-order"] = filter.sortOrder
    }
    return params
}

export const backendApi = createApi({
    reducerPath: 'backendApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['AdministrativeAndFunctionalZone', 'TechnologicalOperation', 'ProductionOrder', 'ProductionOrderExecution', 'ProductionOrderAnalysisByTime', 'PositionsForProductionOrderExecution', 'TasksForProductionOrderExecution', 'ProductionTask', 'WorkOrder', 'ProductionAreaInfo', 'Nomenclature', 'NomenclatureFile', 'Users', 'TechnologicalProcessChart', 'MaterialOfBillOfMaterials', 'ProductOfBillOfMaterials', 'StepOfBillOfMaterials', 'StageOfBillOfMaterials', 'NomenclatureAttachment', 'AdministrativeRole', 'ProductionStage', 'UserCard', 'Individual', 'IndividualPhoto', 'MeasurementUnit', 'MeasurementOkei'],
    endpoints: build => ({

        searchNomenclatureGuidePage:
            build.query<TablePage<NomenclatureGuideRow>, NomenclatureGuideFilter>({
                query: (filter: NomenclatureGuideFilter) => ({
                    url: '/api/v1/nomenclature/guide',
                    params: paramsForNomenclatureQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({id}) => ({type: 'Nomenclature' as const, id: id})),
                            {type: 'Nomenclature', id: 'LIST'},
                        ]
                        : [{type: 'Nomenclature', id: 'LIST'}]
            }),
        createNomenclature: build.mutation<number, NomenclatureForm>({
            query: (body: NomenclatureForm) => ({
                method: 'POST',
                url: '/api/v1/nomenclature',
                body: body
            }),
            invalidatesTags: [{type: 'Nomenclature', id: 'LIST'}]
        }),
        updateNomenclature: build.mutation<void, { id: number, body: NomenclatureForm }>({
            query: (args: { id: number, body: NomenclatureForm }) => ({
                method: 'PUT',
                url: `/api/v1/nomenclature/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, arg) => [{
                type: 'Nomenclature',
                id: arg.id
            }, {type: 'TechnologicalProcessChart', id: 'LIST'}, {
                type: 'MaterialOfBillOfMaterials',
                id: 'LIST'
            }, {type: 'ProductOfBillOfMaterials', id: 'LIST'}, {type: 'StepOfBillOfMaterials', id: 'LIST'}]
        }),
        deleteNomenclature: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `/api/v1/nomenclature/${id}`
            }),
            invalidatesTags: [{type: 'Nomenclature', id: 'LIST'}]
        }),
        getNomenclature: build.query<NomenclatureForm, number>({
            query: (id: number) => ({
                url: `/api/v1/nomenclature/${id}`
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'Nomenclature', id: arg},
                        {type: 'Nomenclature', id: 'LIST'},
                    ]
                    : [{type: 'Nomenclature', id: 'LIST'}]
        }),
        getNomenclatureAttachments: build.query<NomenclatureAttachment[], number>({
            query: (nomenclatureId: number) => ({
                url: `/api/v2/nomenclature/${nomenclatureId}/attachment`
            }),
            providesTags: (result, _error, nomenclatureId) =>
                result
                    ? [
                        ...result.map((file) => ({type: 'NomenclatureAttachment' as const, id: file.fileId})),
                        {type: 'NomenclatureAttachment', id: `LIST${nomenclatureId}`},
                    ]
                    : [{type: 'NomenclatureAttachment', id: `LIST${nomenclatureId}`}]
        }),
        uploadNomenclatureAttachments: build.mutation<void, { nomenclatureId: number, body: FormData }>({
            query: (args: { nomenclatureId: number, body: FormData }) => ({
                method: 'POST',
                url: `/api/v2/nomenclature/${args.nomenclatureId}/attachment`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{
                type: 'NomenclatureAttachment',
                id: `LIST${args.nomenclatureId}`
            }]
        }),
        deleteNomenclatureAttachment: build.mutation<void, { nomenclatureId: number, fileId: string }>({
            query: (args: { nomenclatureId: number, fileId: string }) => ({
                method: 'DELETE',
                url: `/api/v2/nomenclature/${args.nomenclatureId}/attachment/${args.fileId}`
            }),
            invalidatesTags: (_result, _error, args) => [{
                type: 'NomenclatureAttachment',
                id: `LIST${args.nomenclatureId}`
            }]
        }),
        getFile: build.query<Blob, string>({
            query: (id: string) => ({
                url: `api/v2/file`,
                params: {
                    id: id,
                    disposition: "inline"
                },
                responseHandler: (response) => response.blob()
            }),
            keepUnusedDataFor: 5,
            providesTags: [{type: 'NomenclatureFile', id: 'LIST'}]
        }),
        getAllFiles: build.query<Blob, string>({
            query: (ids: string) => ({
                url: `api/v2/file?${ids}`,
                params: {
                    disposition: "inline"
                },
                responseHandler: (response) => response.blob()
            }),
            keepUnusedDataFor: 5,
            providesTags: [{type: 'NomenclatureFile', id: 'LIST'}]
        }),

        searchMeasurementUnitGuidePage: build.query<TablePage<MeasurementUnit>, MeasurementGuideFilter>({
            query: (filter: MeasurementGuideFilter) => ({
                url: '/api/v2/measurement-unit/guide',
                params: paramsForMeasurementUnitOrOkeiQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'MeasurementUnit' as const, id: id})),
                        {type: 'MeasurementUnit', id: 'LIST'},
                    ]
                    : [{type: 'MeasurementUnit', id: 'LIST'}]
        }),
        getMeasurementUnit: build.query<MeasurementUnit, number>({
            query: (id: number) => ({
                url: `/api/v2/measurement-unit/${id}`
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'MeasurementUnit', id: arg},
                        {type: 'MeasurementUnit', id: 'LIST'},
                    ]
                    : [{type: 'MeasurementUnit', id: 'LIST'}]
        }),
        createMeasurementUnit: build.mutation<number, UpdateOrCreateMeasurementUnit>({
            query: (body: UpdateOrCreateMeasurementUnit) => ({
                method: 'POST',
                url: '/api/v2/measurement-unit',
                body: body
            }),
            invalidatesTags: [{type: 'MeasurementUnit', id: 'LIST'}]
        }),
        updateMeasurementUnit: build.mutation<void, { id: number, body: UpdateOrCreateMeasurementUnit }>({
            query: (args: { id: number, body: UpdateOrCreateMeasurementUnit }) => ({
                method: 'PUT',
                url: `/api/v2/measurement-unit/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'MeasurementUnit', id: args.id}]
        }),
        deleteMeasurementUnit: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `/api/v2/measurement-unit/${id}`
            }),
            invalidatesTags: [{type: 'MeasurementUnit', id: 'LIST'}]
        }),

        searchMeasurementOkeiGuidePage: build.query<TablePage<MeasurementOkei>, MeasurementOkeiGuideFilter>({
            query: (filter: MeasurementOkeiGuideFilter) => ({
                url: '/api/v2/measurement-okei/guide',
                params: paramsForMeasurementUnitOrOkeiQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'MeasurementOkei' as const, id: id})),
                        {type: 'MeasurementOkei', id: 'LIST'},
                    ]
                    : [{type: 'MeasurementOkei', id: 'LIST'}]
        }),

        searchAdministrativeRolesGuidePage: build.query<TablePage<AdministrativeRolesRow>, GuideFilter>({
            query: (filter: GuideFilter) => ({
                url: 'api/v2/role/guide',
                params: paramsForAdministrativeRolesQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'AdministrativeRole' as const, id: id})),
                        {type: 'AdministrativeRole', id: 'LIST'}
                    ]
                    : [{type: 'AdministrativeRole', id: 'LIST'}]
        }),
        getAdministrativeRole: build.query<AdministrativeRole, number>({
            query: (id: number) => ({
                url: `api/v2/role/${id}`
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'AdministrativeRole' as const, id: arg},
                        {type: 'AdministrativeRole', id: 'LIST'},
                    ]
                    : [{type: 'AdministrativeRole', id: 'LIST'}]
        }),
        createAdministrativeRole: build.mutation<number, CreatingOrUpdatingAdministrativeRole>({
            query: (body: CreatingOrUpdatingAdministrativeRole) => ({
                method: 'POST',
                url: 'api/v2/role',
                body: body
            }),
            invalidatesTags: [{type: 'AdministrativeRole', id: 'LIST'}]
        }),
        updateAdministrativeRole: build.mutation<void, { id: number, body: CreatingOrUpdatingAdministrativeRole }>({
            query: (args: { id: number, body: CreatingOrUpdatingAdministrativeRole }) => ({
                method: 'PUT',
                url: `api/v2/role/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'AdministrativeRole', id: args.id}]
        }),
        deleteAdministrativeRole: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `api/v2/role/${id}`
            }),
            invalidatesTags: [{type: 'AdministrativeRole', id: 'LIST'}]
        }),

        searchUserGuidePage:
            build.query<TablePage<UserGuideRow>, UserGuideFilter>({
                query: (filter: UserGuideFilter) => ({
                    url: `/api/v2/user/guide`,
                    params: paramsForUsersQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({id}) => ({type: 'Users' as const, id: id})),
                            {type: 'Users', id: 'LIST'},
                        ]
                        : [{type: 'Users', id: 'LIST'}]
            }),
        getUser: build.query<User, number>({
            query: (id: number) => ({
                url: `/api/v2/user/${id}`,
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'Users' as const, id: arg},
                        {type: 'Users', id: 'LIST'},
                    ]
                    : [{type: 'Users', id: 'LIST'}]
        }),
        updateUser: build.mutation<void, { id: number, body: CreatingOrUpdatingUser }>({
            query: (args: { id: number, body: CreatingOrUpdatingUser }) => ({
                method: 'PUT',
                url: `/api/v2/user/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Users', id: args.id}]
        }),
        createUser: build.mutation<number, CreatingOrUpdatingUser>({
            query: (body: CreatingOrUpdatingUser) => ({
                method: 'POST',
                url: `/api/v2/user`,
                body: body
            }),
            invalidatesTags: [{type: 'Users', id: 'LIST'}]
        }),

        searchIndividualsGuidePage: build.query<TablePage<IndividualsRow>, IndividualsGuideFilter>({
            query: (filter: IndividualsGuideFilter) => ({
                url: '/api/v2/individual/guide',
                params: paramsForIndividualsQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'Individual' as const, id: id})),
                        {type: 'Individual', id: 'LIST'},
                    ]
                    : [{type: 'Individual', id: 'LIST'}]
        }),
        getIndividual: build.query<Individuals, number>({
            query: (id: number) => ({
                url: `/api/v2/individual/${id}`
            }),
            providesTags: (_result, _error, id) => [{type: 'Individual', id: id}]
        }),
        createIndividual: build.mutation<number, CreateOrUpdateIndividuals>({
            query: (body: CreateOrUpdateIndividuals) => ({
                method: 'POST',
                url: '/api/v2/individual',
                body: body
            }),
            invalidatesTags: [{type: 'Individual', id: 'LIST'}]
        }),
        updateIndividual: build.mutation<void, { id: number, body: CreateOrUpdateIndividuals }>({
            query: (args: { id: number, body: CreateOrUpdateIndividuals }) => ({
                method: 'PUT',
                url: `/api/v2/individual/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Individual', id: args.id}]
        }),
        deleteIndividual: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `/api/v2/individual/${id}`
            }),
            invalidatesTags: [{type: 'Individual', id: 'LIST'}]
        }),
        uploadIndividualPhoto: build.mutation<void, { id: number, body: FormData, isUserIndividual: boolean }>({
            query: (args: { id: number, body: FormData, isUserIndividual: boolean }) => ({
                method: 'POST',
                url: `/api/v2/individual/${args.id}/photo`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => {
                if (args.isUserIndividual) {
                    return [{type: `IndividualPhoto` as const, id: args.id}, {
                        type: 'Individual',
                        id: args.id
                    }, {type: 'UserCard', id: 'LIST'}]
                } else {
                    return [{type: `IndividualPhoto` as const, id: args.id}, {type: 'Individual', id: args.id}]
                }
            }
        }),
        deleteIndividualPhoto: build.mutation<void, { id: number, isUserIndividual: boolean }>({
            query: (args: { id: number, isUserIndividual: boolean }) => ({
                method: 'DELETE',
                url: `/api/v2/individual/${args.id}/photo`,
            }),
            invalidatesTags: (_result, _error, args) => {
                if (args.isUserIndividual) {
                    return [{type: `IndividualPhoto` as const, id: args.id}, {
                        type: 'Individual',
                        id: args.id
                    }, {type: 'UserCard', id: 'LIST'}]
                } else {
                    return [{type: `IndividualPhoto` as const, id: args.id}, {type: 'Individual', id: args.id}]
                }
            }
        }),

        searchTechnologicalProcessChartGuidePage: build.query<TablePage<TechnologicalProcessChart>, TechnologicalProcessChartGuideFilter>({
            query: (filter: TechnologicalProcessChartGuideFilter) => ({
                url: '/api/v2/technological-process-chart/guide',
                params: paramsForTechnologicalProcessChartQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'TechnologicalProcessChart' as const, id: id})),
                        {type: 'TechnologicalProcessChart', id: 'LIST'},
                    ]
                    : [{type: 'TechnologicalProcessChart', id: 'LIST'}]
        }),
        createBillOfMaterials: build.mutation<number, CreateOrCopyBillOfMaterialsRequest>({
            query: (body: CreateOrCopyBillOfMaterialsRequest) => ({
                method: 'POST',
                url: '/api/v2/bill-of-materials',
                body: body
            }),
            invalidatesTags: [{type: 'TechnologicalProcessChart', id: 'LIST'}, {
                type: 'MaterialOfBillOfMaterials',
                id: 'LIST'
            }, {type: 'ProductOfBillOfMaterials', id: 'LIST'}, {
                type: 'StageOfBillOfMaterials',
                id: 'LIST'
            }, {type: 'StepOfBillOfMaterials', id: 'LIST'}]
        }),
        searchMaterialsOfBillOfMaterials: build.query<Material[], number>({
            query: (billOfMaterialsId: number) => ({
                url: `/api/v2/bill-of-materials/${billOfMaterialsId}/material`
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'MaterialOfBillOfMaterials' as const, id: id})),
                        {type: 'MaterialOfBillOfMaterials', id: 'LIST'},
                    ]
                    : [{type: 'MaterialOfBillOfMaterials', id: 'LIST'}]
        }),
        createMaterialOfBillOfMaterials: build.mutation<number, {
            billOfMaterialsId: number,
            body: EditMaterialOfBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, body: EditMaterialOfBillOfMaterialsRequest }) => ({
                method: 'POST',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/material`,
                body: args.body
            }),
            invalidatesTags: [{type: 'MaterialOfBillOfMaterials', id: 'LIST'}]
        }),
        updateMaterialOfBillOfMaterials: build.mutation<void, {
            billOfMaterialsId: number,
            materialId: number,
            body: EditMaterialOfBillOfMaterialsRequest
        }>({
            query: (args: {
                billOfMaterialsId: number,
                materialId: number,
                body: EditMaterialOfBillOfMaterialsRequest
            }) => ({
                method: 'PUT',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/material/${args.materialId}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, arg) => [{
                type: 'MaterialOfBillOfMaterials',
                id: arg.materialId
            }, {type: 'StepOfBillOfMaterials', id: "LIST"}]
        }),
        deleteMaterialOfBillOfMaterials: build.mutation<void, { billOfMaterialsId: number, materialId: number }>({
            query: (args: { billOfMaterialsId: number, materialId: number }) => ({
                method: 'DELETE',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/material/${args.materialId}`
            }),
            invalidatesTags: [{type: 'MaterialOfBillOfMaterials', id: 'LIST'}, {
                type: 'StepOfBillOfMaterials',
                id: "LIST"
            }]
        }),
        searchProductsOfBillOfMaterials: build.query<Product[], number>({
            query: (billOfMaterialsId: number) => ({
                url: `/api/v2/bill-of-materials/${billOfMaterialsId}/product`
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'ProductOfBillOfMaterials' as const, id: id})),
                        {type: 'ProductOfBillOfMaterials', id: 'LIST'},
                    ]
                    : [{type: 'ProductOfBillOfMaterials', id: 'LIST'}]
        }),
        createProductOfBillOfMaterials: build.mutation<number, {
            billOfMaterialsId: number,
            body: EditProductOfBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, body: EditProductOfBillOfMaterialsRequest }) => ({
                method: 'POST',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/product`,
                body: args.body
            }),
            invalidatesTags: [{type: 'ProductOfBillOfMaterials', id: 'LIST'}]
        }),
        updateProductOfBillOfMaterials: build.mutation<void, {
            billOfMaterialsId: number,
            productId: number,
            body: EditProductOfBillOfMaterialsRequest
        }>({
            query: (args: {
                billOfMaterialsId: number,
                productId: number,
                body: EditProductOfBillOfMaterialsRequest
            }) => ({
                method: 'PUT',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/product/${args.productId}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, arg) => [{
                type: 'ProductOfBillOfMaterials',
                id: arg.productId
            }, {type: 'StepOfBillOfMaterials', id: "LIST"}]
        }),
        deleteProductOfBillOfMaterials: build.mutation<void, { billOfMaterialsId: number, productId: number }>({
            query: (args: { billOfMaterialsId: number, productId: number }) => ({
                method: 'DELETE',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/product/${args.productId}`
            }),
            invalidatesTags: [{type: 'ProductOfBillOfMaterials', id: 'LIST'}, {
                type: 'StepOfBillOfMaterials',
                id: "LIST"
            }]
        }),
        searchStepsOfBillOfMaterials: build.query<Step[], number>({
            query: (billOfMaterialsId: number) => ({
                url: `/api/v2/bill-of-materials/${billOfMaterialsId}/step`
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'StepOfBillOfMaterials' as const, id: id})),
                        {type: 'StepOfBillOfMaterials', id: 'LIST'},
                    ]
                    : [{type: 'StepOfBillOfMaterials', id: 'LIST'}]
        }),
        createStepOfBillOfMaterials: build.mutation<void, {
            billOfMaterialsId: number,
            body: CreateStepOfBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, body: CreateStepOfBillOfMaterialsRequest }) => ({
                method: 'POST',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/step`,
                body: args.body
            }),
            invalidatesTags: [{type: 'StepOfBillOfMaterials', id: 'LIST'}]
        }),
        deleteStepOfBillOfMaterials: build.mutation<void, { billOfMaterialsId: number, stepId: number }>({
            query: (args: { billOfMaterialsId: number, stepId: number }) => ({
                method: 'DELETE',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/step/${args.stepId}`
            }),
            invalidatesTags: [{type: 'StepOfBillOfMaterials', id: 'LIST'}, {
                type: 'MaterialOfBillOfMaterials',
                id: 'LIST'
            }, {type: 'ProductOfBillOfMaterials', id: 'LIST'}]
        }),
        searchStagesOfBillOfMaterials: build.query<Stage[], number>({
            query: (billOfMaterialsId: number) => ({
                url: `/api/v2/bill-of-materials/${billOfMaterialsId}/stage`
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'StageOfBillOfMaterials' as const, id: id})),
                        {type: 'StageOfBillOfMaterials', id: 'LIST'},
                    ]
                    : [{type: 'StageOfBillOfMaterials', id: 'LIST'}]
        }),
        createStageOfBillOfMaterials: build.mutation<number, {
            billOfMaterialsId: number,
            body: EditStageOfBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, body: EditStageOfBillOfMaterialsRequest }) => ({
                method: 'POST',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/stage`,
                body: args.body
            }),
            invalidatesTags: [{type: 'StageOfBillOfMaterials', id: 'LIST'}]
        }),
        updateStageOfBillOfMaterials: build.mutation<void, {
            billOfMaterialsId: number,
            stageId: number,
            body: EditStageOfBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, stageId: number, body: EditStageOfBillOfMaterialsRequest }) => ({
                method: 'PUT',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/stage/${args.stageId}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, arg) => [{type: 'StageOfBillOfMaterials', id: arg.stageId}]
        }),
        deleteStageOfBillOfMaterials: build.mutation<void, { billOfMaterialsId: number, stageId: number }>({
            query: (args: { billOfMaterialsId: number, stageId: number }) => ({
                method: 'DELETE',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/stage/${args.stageId}`
            }),
            invalidatesTags: [{type: 'StageOfBillOfMaterials', id: 'LIST'}, {
                type: 'StepOfBillOfMaterials',
                id: 'LIST'
            }, {type: 'ProductOfBillOfMaterials', id: 'LIST'}, {type: 'MaterialOfBillOfMaterials', id: 'LIST'}]
        }),
        deleteTechnologicalProcessChart: build.mutation<void, number>({
            query: (billOfMaterialsId: number) => ({
                method: 'DELETE',
                url: `/api/v2/bill-of-materials/${billOfMaterialsId}`
            }),
            invalidatesTags: [{type: 'StageOfBillOfMaterials', id: 'LIST'}, {type: 'StepOfBillOfMaterials', id: 'LIST'},
                {type: 'ProductOfBillOfMaterials', id: 'LIST'}, {type: 'MaterialOfBillOfMaterials', id: 'LIST'},
                {type: 'TechnologicalProcessChart', id: 'LIST'}]
        }),
        copyTechnologicalProcessChart: build.mutation<number, {
            billOfMaterialsId: number,
            body: CreateOrCopyBillOfMaterialsRequest
        }>({
            query: (args: { billOfMaterialsId: number, body: CreateOrCopyBillOfMaterialsRequest }) => ({
                method: 'POST',
                url: `/api/v2/bill-of-materials/${args.billOfMaterialsId}/copy`,
                body: args.body
            }),
            invalidatesTags: [{type: 'StageOfBillOfMaterials', id: 'LIST'}, {type: 'StepOfBillOfMaterials', id: 'LIST'},
                {type: 'ProductOfBillOfMaterials', id: 'LIST'}, {type: 'MaterialOfBillOfMaterials', id: 'LIST'},
                {type: 'TechnologicalProcessChart', id: 'LIST'}]
        }),

        searchProductionStagesGuidePage: build.query<TablePage<ProductionStage>, ProductionStageGuideFilter>({
            query: (filter: ProductionStageGuideFilter) => ({
                url: '/api/v2/production-stage/guide',
                params: paramsForProductionStagesQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'ProductionStage' as const, id: id})),
                        {type: 'ProductionStage', id: 'LIST'},
                    ]
                    : [{type: 'ProductionStage', id: 'LIST'}]
        }),
        getProductionStage: build.query<ProductionStage, number>({
            query: (id: number) => ({
                url: `/api/v2/production-stage/${id}`,
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'ProductionStage' as const, id: arg},
                        {type: 'ProductionStage', id: 'LIST'},
                    ]
                    : [{type: 'ProductionStage', id: 'LIST'}]
        }),
        createProductionStage: build.mutation<number, CreatingOrUpdatingProductionStage>({
            query: (body: CreatingOrUpdatingProductionStage) => ({
                method: 'POST',
                url: '/api/v2/production-stage',
                body: body
            }),
            invalidatesTags: [{type: 'ProductionStage', id: 'LIST'}]
        }),
        updateProductionStage: build.mutation<void, { id: number, body: CreatingOrUpdatingProductionStage }>({
            query: (args: { id: number, body: CreatingOrUpdatingProductionStage }) => ({
                method: 'PUT',
                url: `/api/v2/production-stage/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'ProductionStage', id: args.id}]
        }),
        deleteProductionStage: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `/api/v2/production-stage/${id}`
            }),
            invalidatesTags: [{type: 'ProductionStage', id: 'LIST'}]
        }),

        searchTechnologicalOperationGuidePage:
            build.query<TablePage<TechnologicalOperationGuideRow>, TechnologicalOperationGuideFilter>({
                query: (filter: TechnologicalOperationGuideFilter) => ({
                    url: '/api/v2/technological-operation/guide',
                    params: paramsForTechnologicalOperationQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({id}) => ({type: 'TechnologicalOperation' as const, id: id})),
                            {type: 'TechnologicalOperation', id: 'LIST'},
                        ]
                        : [{type: 'TechnologicalOperation', id: 'LIST'}]
            }),
        createTechnologicalOperation:
            build.mutation<number, CreateOrUpdateTechnologicalOperation>({
                query: (body: TechnologicalOperation) => ({
                    method: 'POST',
                    url: `/api/v2/technological-operation`,
                    body: body
                }),
                invalidatesTags: [{type: 'TechnologicalOperation', id: 'LIST'}]
            }),
        updateTechnologicalOperation:
            build.mutation<void, { id: number, body: CreateOrUpdateTechnologicalOperation }>({
                query: (args: { id: number, body: TechnologicalOperation }) => ({
                    method: 'PUT',
                    url: `/api/v2/technological-operation/${args.id}`,
                    body: args.body
                }),
                invalidatesTags: (_result, _error, args) => [{type: 'TechnologicalOperation', id: args.id}]
            }),
        deleteTechnologicalOperation:
            build.mutation<void, number>({
                query: (id: number) => ({
                    method: 'DELETE',
                    url: `/api/v2/technological-operation/${id}`
                }),
                invalidatesTags: [{type: 'TechnologicalOperation', id: 'LIST'}]
            }),
        getTechnologicalOperation:
            build.query<TechnologicalOperation, number>({
                query: (id: number) => ({
                    url: `/api/v2/technological-operation/${id}`
                }),
                providesTags: (result, _error, arg) =>
                    result
                        ? [
                            {type: 'TechnologicalOperation' as const, id: arg},
                            {type: 'TechnologicalOperation', id: 'LIST'},
                        ]
                        : [{type: 'TechnologicalOperation', id: 'LIST'}]
            }),

        searchProductionOrderJournalPage:
            build.query<TablePage<ProductionOrderJournalRow>, ProductionOrderJournalFilter>({
                query: (filter: ProductionOrderJournalFilter) => ({
                    url: '/api/v2/production-order/journal',
                    params: paramsForProductionOrderQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({documentNumber}) => ({
                                type: 'ProductionOrder' as const,
                                id: documentNumber
                            })),
                            {type: 'ProductionOrder', id: 'LIST'},
                        ]
                        : [{type: 'ProductionOrder', id: 'LIST'}]
            }),
        getProductionOrder:
            build.query<ProductionOrder, number>({
                query: (number: number) => ({
                    url: `/api/v2/production-order/${number}`
                }),
                providesTags: (result) =>
                    result
                        ? [
                            {type: 'ProductionOrder' as const, id: result.documentNumber},
                            {type: 'ProductionOrder', id: 'LIST'},
                        ]
                        : [{type: 'ProductionOrder', id: 'LIST'}]
            }),
        updateProductionOrder:
            build.mutation<void, { number: number, body: UpdateOrCreateProductionOrderRequest }>({
                query: (args: { number: number, body: UpdateOrCreateProductionOrderRequest }) => ({
                    method: 'PUT',
                    url: `/api/v2/production-order/${args.number}`,
                    body: args.body
                }),
                invalidatesTags: (_result, _error, arg) => [{type: 'ProductionOrder', id: arg.number}, {type: 'ProductionOrderExecution', id: arg.number}, {type: 'ProductionOrderAnalysisByTime', id: arg.number}]
            }),
        createProductionOrder:
            build.mutation<number, UpdateOrCreateProductionOrderRequest>({
                query: (body: UpdateOrCreateProductionOrderRequest) => ({
                    method: 'POST',
                    url: '/api/v2/production-order',
                    body: body
                }),
                invalidatesTags: [{type: 'ProductionOrder', id: 'LIST'}, {type: 'ProductionOrderExecution', id: 'LIST'}, {type: 'ProductionOrderAnalysisByTime', id: 'LIST'}]
            }),
        deleteProductionOrder: build.mutation<void, number>({
            query: (number: number) => ({
                method: 'DELETE',
                url: `/api/v2/production-order/${number}`
            }),
            invalidatesTags: [{type: 'ProductionOrder', id: 'LIST'}, {type: 'ProductionOrderExecution', id: 'LIST'}, {type: 'ProductionOrderAnalysisByTime', id: 'LIST'}]
        }),
        updateProductionOrderStatus: build.mutation<void, { number: number, body: ProductionOrderStatus }>({
            query: (args: { number: number, body: ProductionOrderStatus }) => ({
                method: 'PUT',
                headers: {
                    "content-type": "application/json"
                },
                url: `/api/v2/production-order/${args.number}/status`,
                body: `"${args.body}"`
            }),
            invalidatesTags: (_result, _error, arg) => [{
                type: 'ProductionOrder',
                id: arg.number
            }, {type: 'ProductionOrderExecution', id: arg.number}, {type: 'ProductionOrderAnalysisByTime', id: arg.number}]
        }),

        searchProductionOrderAnalysisByTimeReportPage:
            build.query<TablePage<ProductionOrderAnalysisByTime>, ProductionOrderAnalysisByTimeReportFilter>({
                query: (filter: ProductionOrderAnalysisByTimeReportFilter) => ({
                    url: '/api/v2/production-order/analysis-execution-by-time/report',
                    params: paramsForProductionOrderQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({documentNumber}) => ({
                                type: 'ProductionOrderAnalysisByTime' as const,
                                id: documentNumber
                            })),
                            {type: 'ProductionOrderAnalysisByTime', id: 'LIST'},
                        ]
                        : [{type: 'ProductionOrderAnalysisByTime', id: 'LIST'}]
            }),

        searchProductionOrderExecutionJournalPage:
            build.query<TablePage<ProductionOrderExecutionJournalRow>, ProductionOrderExecutionJournalFilter>({
                query: (filter: ProductionOrderExecutionJournalFilter) => ({
                    url: '/api/v2/production-order/execution/journal',
                    params: paramsForProductionOrderExecutionQuery(filter)
                }),
                providesTags: (result) =>
                    result
                        ? [
                            ...result.rows.map(({documentNumber}) => ({
                                type: 'ProductionOrderExecution' as const,
                                id: documentNumber
                            })),
                            {type: 'ProductionOrderExecution', id: 'LIST'},
                        ]
                        : [{type: 'ProductionOrderExecution', id: 'LIST'}]
            }),
        getPositionsForProductionOrderExecution:
            build.query<PositionsForProductionOrderExecution, number>({
                query: (productionOrderDocumentNumber: number) => ({
                    url: `/api/v2/production-order/execution/${productionOrderDocumentNumber}`
                }),
                providesTags: (result) =>
                    result
                        ? [
                            {
                                type: 'PositionsForProductionOrderExecution' as const,
                                id: result.productionOrderDocumentNumber
                            },
                            {type: 'PositionsForProductionOrderExecution', id: 'LIST'},
                        ]
                        : [{type: 'PositionsForProductionOrderExecution', id: 'LIST'}]
            }),
        getTasksForProductionOrderExecution: build.query<TasksForProductionOrderExecution, number>({
            query: (productionOrderDocumentNumber: number) => ({
                url: `/api/v2/production-order/execution/${productionOrderDocumentNumber}/task`
            }),
            providesTags: (result) =>
                result
                    ? [
                        {type: 'TasksForProductionOrderExecution' as const, id: result.productionOrderDocumentNumber},
                        {type: 'TasksForProductionOrderExecution', id: 'LIST'},
                    ]
                    : [{type: 'TasksForProductionOrderExecution', id: 'LIST'}]
        }),
        updateProductionTask:
            build.mutation<void, { productionOrderDocumentNumber: number, documentNumber: number, quantity: string }>({
                query: (args: { productionOrderDocumentNumber: number, documentNumber: number, quantity: string }) => ({
                    method: 'PUT',
                    headers: {
                        "content-type": "application/json"
                    },
                    url: `/api/v2/production-task/${args.documentNumber}`,
                    body: args.quantity
                }),
                invalidatesTags: (_result, _error, args) => [{
                    type: 'TasksForProductionOrderExecution',
                    id: args.productionOrderDocumentNumber
                }, {
                    type: 'ProductionOrderExecution',
                    id: 'LIST'
                }, {
                    type: 'ProductionOrder',
                    id: args.productionOrderDocumentNumber
                }, {
                    type: 'ProductionOrderAnalysisByTime',
                    id: args.productionOrderDocumentNumber
                }]
            }),
        updateProductionTaskStatus: build.mutation<void, {
            productionOrderDocumentNumber: number,
            documentNumber: number,
            body: ProductionTaskStatus
        }>({
            query: (args: {
                productionOrderDocumentNumber: number,
                documentNumber: number,
                body: ProductionTaskStatus
            }) => ({
                method: 'PUT',
                headers: {
                    "content-type": "application/json"
                },
                url: `/api/v2/production-task/${args.documentNumber}/status`,
                body: `"${args.body}"`
            }),
            invalidatesTags: (_result, _error, args) => [{
                type: 'TasksForProductionOrderExecution',
                id: args.productionOrderDocumentNumber
            }, {
                type: 'ProductionOrderExecution',
                id: 'LIST'
            }, {
                type: 'ProductionOrder',
                id: args.productionOrderDocumentNumber
            }, {
                type: 'ProductionOrderAnalysisByTime',
                id: args.productionOrderDocumentNumber
            }]
        }),
        searchProductionTaskAttachments: build.query<ProductionTaskAttachment[], number>({
            query: (documentNumber: number) => ({
                url: `/api/v2/production-task/${documentNumber}/attachment`
            })
        }),

        searchAdministrativeAndFunctionalZoneGuidePage: build.query<TablePage<AdministrativeAndFunctionalZoneGuideRow>, AdministrativeAndFunctionalZoneGuideFilter>({
            query: (filter: AdministrativeAndFunctionalZoneGuideFilter) => ({
                url: '/api/v2/administrative-and-functional-zone/guide',
                params: paramsForAdministrativeAndFunctionalZoneQuery(filter)
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'AdministrativeAndFunctionalZone' as const, id: id})),
                        {type: 'AdministrativeAndFunctionalZone', id: 'LIST'},
                    ]
                    : [{type: 'AdministrativeAndFunctionalZone', id: 'LIST'}]
        }),
        createAdministrativeAndFunctionalZone: build.mutation<number, CreateOrUpdateAdministrativeAndFunctionalZone>({
            query: (body: CreateOrUpdateAdministrativeAndFunctionalZone) => ({
                method: 'POST',
                url: '/api/v2/administrative-and-functional-zone',
                body: body
            }),
            invalidatesTags: [{type: 'AdministrativeAndFunctionalZone', id: 'LIST'}]
        }),
        updateAdministrativeAndFunctionalZone: build.mutation<void, {
            id: number,
            body: CreateOrUpdateAdministrativeAndFunctionalZone
        }>({
            query: (args: { id: number, body: CreateOrUpdateAdministrativeAndFunctionalZone }) => ({
                method: 'PUT',
                url: `/api/v2/administrative-and-functional-zone/${args.id}`,
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'AdministrativeAndFunctionalZone', id: args.id}]
        }),
        deleteAdministrativeAndFunctionalZone: build.mutation<void, number>({
            query: (id: number) => ({
                method: 'DELETE',
                url: `/api/v2/administrative-and-functional-zone/${id}`
            }),
            invalidatesTags: [{type: 'AdministrativeAndFunctionalZone', id: 'LIST'}]
        }),
        getAdministrativeAndFunctionalZone: build.query<AdministrativeAndFunctionalZone, number>({
            query: (id: number) => ({
                url: `/api/v2/administrative-and-functional-zone/${id}`
            }),
            providesTags: (result, _error, arg) =>
                result
                    ? [
                        {type: 'AdministrativeAndFunctionalZone', id: arg},
                        {type: 'AdministrativeAndFunctionalZone', id: 'LIST'},
                    ]
                    : [{type: 'AdministrativeAndFunctionalZone', id: 'LIST'}]
        }),

        updateUserProfile: build.mutation<void, { id: number, body: UpdateUserProfile }>({
            query: (args: { id: number, body: UpdateUserProfile }) => ({
                method: 'PUT',
                url: '/api/v2/user/profile',
                body: args.body
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'UserCard', id: 'LIST'}, {
                type: 'Users',
                id: args.id
            }, {type: 'Individual', id: 'LIST'}]
        }),

        getTokens: build.mutation<Tokens, UsernamePassword>({
            query: ({username, password}: UsernamePassword) => ({
                method: 'POST',
                headers: {'Authorization': `Basic ${window.btoa(String.fromCodePoint.apply(null, Array.from(new TextEncoder().encode(`${username}:${password}`))))}`},
                url: '/api/v1/jwt/tokens'
            })
        }),

        invalidateToken: build.mutation<void, Tokens>({
            query: ({refreshToken}: Tokens) => ({
                method: 'POST',
                headers: {'Authorization': `Bearer ${refreshToken}`},
                url: '/api/v1/jwt/invalidate'
            }),
            invalidatesTags: [{type: 'UserCard', id: 'LIST'}]
        }),

        getUserCard: build.query<UserCard, void>({
            query: _ => ({
                url: '/api/v2/user/card'
            }),
            providesTags: [{type: 'UserCard', id: 'LIST'}]
        })
    })
})

export const {
    useSearchNomenclatureGuidePageQuery,
    useLazySearchNomenclatureGuidePageQuery,
    useCreateNomenclatureMutation,
    useDeleteNomenclatureMutation,
    useGetNomenclatureQuery,
    useUpdateNomenclatureMutation,
    useLazyGetNomenclatureQuery,
    useGetNomenclatureAttachmentsQuery,
    useUploadNomenclatureAttachmentsMutation,
    useDeleteNomenclatureAttachmentMutation,
    useGetFileQuery,
    useLazyGetFileQuery,
    useLazyGetAllFilesQuery,

    useSearchMeasurementUnitGuidePageQuery,
    useLazySearchMeasurementUnitGuidePageQuery,
    useGetMeasurementUnitQuery,
    useCreateMeasurementUnitMutation,
    useUpdateMeasurementUnitMutation,
    useDeleteMeasurementUnitMutation,

    useSearchMeasurementOkeiGuidePageQuery,

    useSearchAdministrativeRolesGuidePageQuery,
    useCreateAdministrativeRoleMutation,
    useUpdateAdministrativeRoleMutation,
    useDeleteAdministrativeRoleMutation,
    useGetAdministrativeRoleQuery,
    useLazyGetAdministrativeRoleQuery,
    useLazySearchAdministrativeRolesGuidePageQuery,

    useSearchProductionStagesGuidePageQuery,
    useLazySearchProductionStagesGuidePageQuery,
    useGetProductionStageQuery,
    useCreateProductionStageMutation,
    useUpdateProductionStageMutation,
    useDeleteProductionStageMutation,

    useSearchUserGuidePageQuery,
    useLazySearchUserGuidePageQuery,
    useGetUserQuery,
    useLazyGetUserQuery,
    useUpdateUserMutation,
    useCreateUserMutation,

    useSearchIndividualsGuidePageQuery,
    useLazySearchIndividualsGuidePageQuery,
    useGetIndividualQuery,
    useCreateIndividualMutation,
    useUpdateIndividualMutation,
    useDeleteIndividualMutation,
    useLazyGetIndividualQuery,
    useUploadIndividualPhotoMutation,
    useDeleteIndividualPhotoMutation,

    useSearchTechnologicalProcessChartGuidePageQuery,
    useLazySearchTechnologicalProcessChartGuidePageQuery,
    useCreateBillOfMaterialsMutation,
    useSearchMaterialsOfBillOfMaterialsQuery,
    useCreateMaterialOfBillOfMaterialsMutation,
    useUpdateMaterialOfBillOfMaterialsMutation,
    useDeleteMaterialOfBillOfMaterialsMutation,
    useSearchProductsOfBillOfMaterialsQuery,
    useLazySearchProductsOfBillOfMaterialsQuery,
    useCreateProductOfBillOfMaterialsMutation,
    useUpdateProductOfBillOfMaterialsMutation,
    useDeleteProductOfBillOfMaterialsMutation,
    useSearchStepsOfBillOfMaterialsQuery,
    useLazySearchStepsOfBillOfMaterialsQuery,
    useCreateStepOfBillOfMaterialsMutation,
    useDeleteStepOfBillOfMaterialsMutation,
    useSearchStagesOfBillOfMaterialsQuery,
    useCreateStageOfBillOfMaterialsMutation,
    useUpdateStageOfBillOfMaterialsMutation,
    useDeleteStageOfBillOfMaterialsMutation,
    useDeleteTechnologicalProcessChartMutation,
    useCopyTechnologicalProcessChartMutation,

    useSearchTechnologicalOperationGuidePageQuery,
    useCreateTechnologicalOperationMutation,
    useUpdateTechnologicalOperationMutation,
    useDeleteTechnologicalOperationMutation,
    useLazyGetTechnologicalOperationQuery,
    useGetTechnologicalOperationQuery,

    useSearchProductionOrderJournalPageQuery,
    useSearchProductionTaskAttachmentsQuery,
    useCreateProductionOrderMutation,
    useGetProductionOrderQuery,
    useUpdateProductionOrderMutation,
    useDeleteProductionOrderMutation,
    useUpdateProductionOrderStatusMutation,

    useSearchProductionOrderAnalysisByTimeReportPageQuery,

    useSearchProductionOrderExecutionJournalPageQuery,
    useGetPositionsForProductionOrderExecutionQuery,
    useLazyGetPositionsForProductionOrderExecutionQuery,
    useGetTasksForProductionOrderExecutionQuery,
    useUpdateProductionTaskMutation,
    useUpdateProductionTaskStatusMutation,

    useSearchAdministrativeAndFunctionalZoneGuidePageQuery,
    useLazySearchAdministrativeAndFunctionalZoneGuidePageQuery,
    useCreateAdministrativeAndFunctionalZoneMutation,
    useUpdateAdministrativeAndFunctionalZoneMutation,
    useDeleteAdministrativeAndFunctionalZoneMutation,
    useLazyGetAdministrativeAndFunctionalZoneQuery,
    useGetAdministrativeAndFunctionalZoneQuery,

    useUpdateUserProfileMutation,
    useLazyGetUserCardQuery,
    useGetUserCardQuery,
    useGetTokensMutation,
    useInvalidateTokenMutation
} = backendApi