import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {TablePage} from "../../model/utils/TablePage";

interface InitialStateInterface {
    data: {
        [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.USER_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: TablePage<any> | null,
        [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: TablePage<any> | null,

        [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: TablePage<any> | null,
        [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: TablePage<any> | null,
        [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: TablePage<any> | null,
        [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: TablePage<any> | null
    }
}
const initialState: InitialStateInterface = {
    data: {
        [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]: null,
        [TableFilterKeys.USER_GUIDE_FILTER]: null,
        [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER]: null,
        [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]: null,

        [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]: null,
        [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]: null,
        [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]: null,
        [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]: null,
        [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]: null,
        [TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER]: null,
        [TableFilterKeys.PRODUCTION_ORDER_ARCHIVE_FILTER]: null,
        [TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]: null,
        [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]: null,
        [TableFilterKeys.MEASUREMENT_OKEI_GUIDE_FILTER]: null
    }
}

interface AddData {
    key: TableFilterKeys,
    data: TablePage<any>
}

export const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        addData: (state, action: PayloadAction<AddData>) => {
            if (state.data[action.payload.key] !== null) {
                state.data[action.payload.key] = {...action.payload.data, rows: state.data[action.payload.key]!.rows.concat(action.payload.data.rows)}
            } else {
                state.data[action.payload.key] = action.payload.data
            }
        },
        clearData: (state, action: PayloadAction<TableFilterKeys>) => {
            if (state.data[action.payload] !== null) {
                state.data[action.payload] = null
            }
        }
    }
})

export const dataReducer = dataSlice.reducer
export const dataActions = dataSlice.actions
export const {clearData} = dataSlice.actions