import React, {ReactNode, useEffect, useState} from "react";
import {RiExpandUpDownFill} from "react-icons/ri";
import {FcGenericSortingAsc, FcGenericSortingDesc} from "react-icons/fc";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {ObservableElement} from "../ui/table-components/ObservableElement";
import {useAppSelector} from "../../hooks/useAppSelector";

export interface TableProps<T> {
    tableColumns: TableColumn<T>[]
    rows: T[],
    onRowClick?: (row: T) => void
    pagination: Pagination,
    tableFilterKey: TableFilterKeys,
    getTableRowBgColor?: (row: T) => string,
    getTableRowTitle?: (row: T) => string,
    sortRows?: SortRows<T>,
}

export interface TableColumn<T> {
    columnName: string
    columnFieldName?: string
    sortingFunction?: () => void
    extractCellContent: (row: T) => ReactNode | any[],
    alignmentInCell?: string
    additionalStyles?: string
    additionalHeaders?: string[]
    additionalHeadersStyles?: string[],
    displayAtList?: boolean,
    showOnOneTableRow?: boolean
}

export interface Pagination {
    currentPageNumber: number
    quantityOfPages: number
    setPageNumber: (newPageNumber: number) => void
}

export interface SortRows<T> {
    sort: (rows: T[]) => T[][],
    separators: ReactNode[]
}

export function Table<T>({rows = [], ...props}: TableProps<T>) {
    const [tableHeight, setTableHeight] = useState(window.innerHeight - 171);
    const guideTopBox = document.getElementById("guideTopBox")
    const mainHeader = document.getElementById("mainHeader")

    const updateTableHeight = () => {
        const boxHeight = guideTopBox ? guideTopBox.offsetHeight : 0;
        const headerHeight = mainHeader ? mainHeader.offsetHeight : 0;
        setTableHeight(window.innerHeight - 58 - boxHeight - headerHeight)
    };

    useEffect(() => {
        const handleResize = () => {
            updateTableHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentRect.height) {
                    updateTableHeight()
                }
            }
        });

        if (guideTopBox) {
            resizeObserver.observe(guideTopBox);
        }
        if (mainHeader) {
            resizeObserver.observe(mainHeader);
        }

        return () => {
            if (guideTopBox) {
                resizeObserver.unobserve(guideTopBox);
            }
            if (mainHeader) {
                resizeObserver.unobserve(mainHeader);
            }
        };
    }, []);


    const state = useAppSelector(state => state.sessionStorage.filters)
    const filter = state[props.tableFilterKey].filter;

    function columnNameMapper(tableColumn: TableColumn<T>): ReactNode {
        let additionalHeader: ReactNode[] = []
        if (tableColumn.additionalHeaders && tableColumn.additionalHeadersStyles) {
            additionalHeader = tableColumn.additionalHeaders.map((header, index) => {
                return <span key={index}
                             className={tableColumn.additionalHeadersStyles ? tableColumn.additionalHeadersStyles[index] : ''}>{header}</span>
            })
        }
        return (
            <th title={tableColumn.columnName} key={tableColumn.columnName}
                className={`align-top whitespace-nowrap pb-1 pt-3 px-[14px] font-normal text-left text-base`}>
                {(tableColumn.sortingFunction === undefined) &&
                    <span className={`${tableColumn.additionalStyles} self-start`}>{tableColumn.columnName}</span>}
                {(tableColumn.sortingFunction !== undefined) && (
                    <div className="w-full h-full flex cursor-pointer flex-row gap-y-2"
                         onClick={tableColumn.sortingFunction}>
                        <span className={tableColumn.additionalStyles}>{tableColumn.columnName}</span>
                        {filter && 'sortedField' in filter && <span className="self-center">
                        {(filter.sortedField === tableColumn.columnFieldName) ?
                            (filter.sortOrder === "ASC" ?
                                <FcGenericSortingAsc className="h-4 w-4"/>
                                : <FcGenericSortingDesc className="h-4 w-4"/>)
                            : <RiExpandUpDownFill className="h-4 w-4"/>}
                    </span>}
                    </div>)}
                {!!additionalHeader && <div className="flex flex-row mt-1">{additionalHeader}</div>}
            </th>
        )
    }

    function rowMapper(row: T, index: number, tableColumns: TableColumn<T>[], onRowClick?: (row: T) => void): ReactNode {
        const rowStyle = onRowClick ? "hover:bg-blue-500/[.10] cursor-pointer" : ""
        return (
            <tr key={index} className={`font-light text-left text-base leading-none ${rowStyle} ${props.getTableRowBgColor?.(row) || ''}`}
                onClick={onRowClick ? () => onRowClick(row) : undefined} title={props.getTableRowTitle?.(row) || ''}>
                {tableColumns.map((tableColumn, index) => cellMapper(row, tableColumn, index, tableColumn.alignmentInCell))}
            </tr>
        )
    }

    function cellMapper(row: T, tableColumn: TableColumn<T>, index: number, alignmentInCell?: string): ReactNode {
        const columnValue = tableColumn.extractCellContent(row)
        if (Array.isArray(columnValue)) {
            return (
                <td key={index} className={`px-[14px] py-1 border-t-[0.5px] border-t-black/[.20] align-middle ${alignmentInCell}`}>
                    {tableColumn.displayAtList && columnValue.map((value, index) => <span key={index}>{`${index + 1}. ${value}`}<br/></span>)}
                    {tableColumn.showOnOneTableRow && <div className="flex flex-row">{columnValue.map((value, index) => <span key={index}>{value}</span>)}</div>}
                    {!tableColumn.displayAtList && !tableColumn.showOnOneTableRow && columnValue.map((value, index) => <span key={index}>{value}<br/></span>)}
                </td>
            )
        }
        return (
            <td key={index}
                className={`px-[14px] py-1 border-t-[0.5px] border-t-black/[.20] align-middle ${alignmentInCell}`}>{tableColumn.extractCellContent(row) || "–"}
            </td>)
    }

    return (<>
        <div
            className={`bg-white min-w-full rounded-lg shadow-lg mt-4 px-3 pb-3 overflow-auto`}
            style={{maxHeight: `${tableHeight}px`}}>
            <table className="min-w-full h-full border-b-[0.5px] border-b-black/[.20]">
                <thead className="sticky top-0 bg-white z-100">
                <tr>
                    {props.tableColumns.map((column) => columnNameMapper(column))}
                </tr>
                </thead>
                <tbody>
                    {!props.sortRows && rows.map((row, index) => rowMapper(row, index, props.tableColumns, props.onRowClick))}
                    {props.sortRows && props.sortRows.sort(rows).map((rowsGroup, index) => [props.sortRows!.separators[index]].concat(rowsGroup.map((row, index) => rowMapper(row, index, props.tableColumns, props.onRowClick))))}
                </tbody>
            </table>
            <ObservableElement currentPageNumber={props.pagination.currentPageNumber}
                               quantityOfPages={props.pagination.quantityOfPages}
                               setPageNumber={props.pagination.setPageNumber}/>
        </div>
    </>)
}