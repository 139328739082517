import React from "react"
import {CollapseButton} from "../navbar/CollapseButton";
import {Navigate, Route, Routes} from "react-router-dom";
import {NomenclatureGuide} from "../../pages/nomenclature/NomenclatureGuide";
import {CreateNomenclatureForm} from "../../pages/nomenclature/CreateNomenclatureForm";
import {MeasurementUnitGuide} from "../../pages/measurement-unit/MeasurementUnitGuide";
import {CreateMeasurementUnitForm} from "../../pages/measurement-unit/CreateMeasurementUnitForm";
import {EditMeasurementUnitForm} from "../../pages/measurement-unit/EditMeasurementUnitForm";
import {UserGuide} from "../../pages/users/UserGuide";
import {CreateUserForm} from "../../pages/users/CreateUserForm";
import {IndividualsGuide} from "../../pages/individuals/IndividualsGuide";
import {TechnologicalProcessChartGuide} from "../../pages/technological-process-chart/TechnologicalProcessChartGuide";
import {
    CreateTechnologicalProcessChartForm
} from "../../pages/technological-process-chart/CreateTechnologicalProcessChartForm";
import {
    ReadAndEditTechnologicalProcessChartForm
} from "../../pages/technological-process-chart/ReadAndEditTechnologicalProcessChartForm";
import {TechnologicalOperationGuide} from "../../pages/tecnological-operation/TechnologicalOperationGuide";
import {CreateTechnologicalOperationForm} from "../../pages/tecnological-operation/CreateTechnologicalOperationForm";
import {ReadTechnologicalOperationForm} from "../../pages/tecnological-operation/ReadTechnologicalOperationForm";
import {ProductionOrderDocumentJournal} from "../../pages/production-order/ProductionOrderDocumentJournal";
import {
    AdministrativeAndFunctionalZoneGuide
} from "../../pages/administrative-and-functional-zone/AdministrativeAndFunctionalZoneGuide";
import {
    CreateAdministrativeAndFunctionalZoneForm
} from "../../pages/administrative-and-functional-zone/CreateAdministrativeAndFunctionalZoneForm";
import {
    ReadAdministrativeAndFunctionalZoneForm
} from "../../pages/administrative-and-functional-zone/ReadAdministrativeAndFunctionalZoneForm";
import {ReadProductionOrderForm} from "../../pages/production-order/ReadProductionOrderForm";
import {EditProductionOrderForm} from "../../pages/production-order/EditProductionOrderForm";
import {CreateProductionOrderForm} from "../../pages/production-order/CreateProductionOrderForm";
import {ReadNomenclatureForm} from "../../pages/nomenclature/ReadNomenclatureForm";
import {EditNomenclatureForm} from "../../pages/nomenclature/EditNomenclatureForm";
import {AdministrativeRolesGuide} from "../../pages/administrative-roles/AdministrativeRolesGuide";
import {CreateAdministrativeRolesForm} from "../../pages/administrative-roles/CreateAdministrativeRolesForm";
import {EditAdministrativeRolesForm} from "../../pages/administrative-roles/EditAdministrativeRolesForm";
import {ReadAdministrativeRolesForm} from "../../pages/administrative-roles/ReadAdministrativeRolesForm";
import {ProductionStageGuide} from "../../pages/production-stages/ProductionStageGuide";
import {CreateProductionStageForm} from "../../pages/production-stages/CreateProductionStageForm";
import {EditProductionStageForm} from "../../pages/production-stages/EditProductionStageForm";
import {ReadProductionStageForm} from "../../pages/production-stages/ReadProductionStageForm";
import {LoginPage} from "../../pages/security/LoginPage";
import {ProtectedRoute} from "../ui/common/ProtectedRoute";
import {Permission} from "../../model/administrative-roles/AdministrativeRole";
import {ReadProfile} from "../../pages/security/ReadProfile";
import {ReadUserForm} from "../../pages/users/ReadUserForm";
import {EditUserForm} from "../../pages/users/EditUserForm";
import {
    ProductionOrderExecutionDocumentJournal
} from "../../pages/production-order-execution/ProductionOrderExecutionDocumentJournal";
import {
    ProductionOrderExecutionFormPage
} from "../../pages/production-order-execution/ProductionOrderExecutionFormPage";
import {CreateIndividualsForm} from "../../pages/individuals/CreateIndividualsForm";
import {ReadIndividualsForm} from "../../pages/individuals/ReadIndividualsForm";
import {EditIndividualsForm} from "../../pages/individuals/EditIndividualsForm";
import {useGetHeader} from "../utils/Utils";
import {ReadMeasurementUnitForm} from "../../pages/measurement-unit/ReadMeasurementUnitForm";
import {MeasurementOkeiGuide} from "../../pages/measurement-okei/MeasurementOkeiGuide";
import {
    EditAdministrativeAndFunctionalZoneForm
} from "../../pages/administrative-and-functional-zone/EditAdministrativeAndFunctionalZoneForm";
import {EditTechnologicalOperationForm} from "../../pages/tecnological-operation/EditTechnologicalOperationForm";
import {useIsAuthorized} from "../../hooks/TokensHooks";
import {EditProfile} from "../../pages/security/EditProfile";
import {ProductionOrderArchiveJournal} from "../../pages/production-order-archive/ProductionOrderArchiveJournal";
import {
    ProductionOrderAnalysisByTimeReport
} from "../../pages/production-order-analysis-by-time/ProductionOrderAnalysisByTimeReport";

export function MainComponent() {
    const header = useGetHeader()
    const isAuthorized = useIsAuthorized()

    return <div className={`sm:max-w-[768px] md:max-w-[1024px] lg:max-w-[1280px] xl:max-w-[1536px] 2xl:max-w-full grow h-screen bg-gray-100 overflow-y-auto`}>
        <div className="flex flex-row">
            <div className="pt-4">
                <CollapseButton/>
            </div>
            <div className="ml-6 pt-[18px] font-light text-lg" id={"mainHeader"}>
                {header}
            </div>
        </div>
        <div className="p-6 pt-[18px]">
            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthorized ? <Navigate to="/profile" /> : <Navigate to="/login" />
                    }
                />
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/profile" element={<ProtectedRoute allAvailable={true}/>}>
                    <Route path="" element={<ReadProfile/>}/>
                    <Route path="edit" element={<EditProfile/>}/>
                </Route>

                <Route path="/nomenclature" element={<ProtectedRoute authority={Permission.NOMENCLATURE_VIEW}/>}>
                    <Route path="guide" element={<NomenclatureGuide/>}/>
                    <Route path="" element={<ReadNomenclatureForm/>}/>
                </Route>
                <Route path="/nomenclature" element={<ProtectedRoute authority={Permission.NOMENCLATURE_MANAGE}/>}>
                    <Route path="new" element={<CreateNomenclatureForm/>}/>
                    <Route path="edit" element={<EditNomenclatureForm/>}/>
                </Route>

                <Route path="/measurement-unit" element={<ProtectedRoute authority={Permission.MEASUREMENT_UNIT_VIEW}/>}>
                    <Route path="guide" element={<MeasurementUnitGuide/>}/>
                    <Route path="" element={<ReadMeasurementUnitForm/>}/>
                </Route>
                <Route path="/measurement-unit" element={<ProtectedRoute authority={Permission.MEASUREMENT_UNIT_MANAGE}/>}>
                    <Route path="new" element={<CreateMeasurementUnitForm/>}/>
                    <Route path="edit" element={<EditMeasurementUnitForm/>}/>
                </Route>
                <Route path="/measurement-okei" element={<ProtectedRoute authority={Permission.MEASUREMENT_UNIT_MANAGE}/>}>
                    <Route path="guide" element={<MeasurementOkeiGuide/>}/>
                </Route>

                <Route path="/administrative-roles"
                       element={<ProtectedRoute authority={Permission.ADMINISTRATIVE_ROLE_VIEW}/>}>
                    <Route path="guide" element={<AdministrativeRolesGuide/>}/>\
                    <Route path="" element={<ReadAdministrativeRolesForm/>}/>
                </Route>
                <Route path="/administrative-roles"
                       element={<ProtectedRoute authority={Permission.ADMINISTRATIVE_ROLE_MANAGE}/>}>
                    <Route path="new" element={<CreateAdministrativeRolesForm/>}/>
                    <Route path="edit" element={<EditAdministrativeRolesForm/>}/>
                </Route>

                <Route path="/user" element={<ProtectedRoute authority={Permission.USER_VIEW}/>}>
                    <Route path="guide" element={<UserGuide/>}/>
                    <Route path="" element={<ReadUserForm/>}/>
                </Route>
                <Route path="/user" element={<ProtectedRoute authority={Permission.USER_MANAGE}/>}>
                    <Route path="new" element={<CreateUserForm/>}/>
                    <Route path="edit" element={<EditUserForm/>}/>
                </Route>

                <Route path="/individuals" element={<ProtectedRoute authority={Permission.INDIVIDUALS_VIEW}/>}>
                    <Route path="guide" element={<IndividualsGuide/>}/>
                    <Route path="" element={<ReadIndividualsForm/>}/>
                </Route>
                <Route path="/individuals" element={<ProtectedRoute authority={Permission.INDIVIDUALS_MANAGE}/>}>
                    <Route path="new" element={<CreateIndividualsForm/>}/>
                    <Route path="edit" element={<EditIndividualsForm/>}/>
                </Route>

                <Route element={<ProtectedRoute authority={Permission.TECHNOLOGICAL_PROCESS_CHART_VIEW}/>}>
                    <Route path="/technological-process-chart/guide"
                           element={<TechnologicalProcessChartGuide/>}/>
                    <Route path="/bill-of-materials"
                           element={<ReadAndEditTechnologicalProcessChartForm/>}/>
                </Route>
                <Route element={<ProtectedRoute authority={Permission.TECHNOLOGICAL_PROCESS_CHART_MANAGE}/>}>
                    <Route path="/bill-of-materials/new"
                           element={<CreateTechnologicalProcessChartForm/>}/>
                </Route>

                <Route path="/administrative-and-functional-zone"
                       element={<ProtectedRoute authority={Permission.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_VIEW}/>}>
                    <Route path="guide"
                           element={<AdministrativeAndFunctionalZoneGuide/>}/>
                    <Route path=""
                           element={<ReadAdministrativeAndFunctionalZoneForm/>}/>
                </Route>
                <Route path="/administrative-and-functional-zone"
                       element={<ProtectedRoute authority={Permission.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_MANAGE}/>}>
                    <Route path="new"
                           element={<CreateAdministrativeAndFunctionalZoneForm/>}/>
                    <Route path="edit"
                           element={<EditAdministrativeAndFunctionalZoneForm/>}/>
                </Route>

                <Route path="/technological-operation"
                       element={<ProtectedRoute authority={Permission.TECHNOLOGICAL_OPERATION_VIEW}/>}>
                    <Route path="guide" element={<TechnologicalOperationGuide/>}/>
                    <Route path="" element={<ReadTechnologicalOperationForm/>}/>
                </Route>

                <Route path="/technological-operation"
                       element={<ProtectedRoute authority={Permission.TECHNOLOGICAL_OPERATION_MANAGE}/>}>
                    <Route path="new" element={<CreateTechnologicalOperationForm/>}/>
                    <Route path="edit" element={<EditTechnologicalOperationForm/>}/>
                </Route>

                <Route path="/production-stages"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_STAGE_VIEW}/>}>
                    <Route path="guide" element={<ProductionStageGuide/>}/>
                    <Route path="" element={<ReadProductionStageForm/>}/>
                </Route>
                <Route path="/production-stages"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_STAGE_MANAGE}/>}>
                    <Route path="edit" element={<EditProductionStageForm/>}/>
                    <Route path="new" element={<CreateProductionStageForm/>}/>
                </Route>

                <Route path="/production-order"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_ORDER_VIEW}/>}>
                    <Route path=""
                           element={<ReadProductionOrderForm/>}/>
                    <Route path="document-journal"
                           element={<ProductionOrderDocumentJournal/>}/>
                </Route>

                <Route path="/production-order"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_ORDER_MANAGE}/>}>
                    <Route path="new" element={<CreateProductionOrderForm/>}/>
                    <Route path="edit" element={<EditProductionOrderForm/>}/>
                </Route>


                <Route path="/production-order/execution"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_ORDER_EXECUTION}/>}>
                    <Route path="document-journal"
                           element={<ProductionOrderExecutionDocumentJournal/>}/>
                    <Route path=""
                           element={<ProductionOrderExecutionFormPage/>}/>
                </Route>

                <Route path="/production-order/archive"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_ORDER_VIEW}/>}>
                    <Route path="document-journal"
                           element={<ProductionOrderArchiveJournal/>}/>
                </Route>

                <Route path="/production-order/analysis-execution-by-time"
                       element={<ProtectedRoute authority={Permission.PRODUCTION_ORDER_VIEW}/>}>
                    <Route path="report"
                           element={<ProductionOrderAnalysisByTimeReport/>}/>
                </Route>
            </Routes>
        </div>
    </div>
}