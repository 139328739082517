import React from "react";
import {AdministrativeRoleFormTemplate} from "./AdministrativeRoleFormTemplate";
import {useSearchProductionStagesGuidePageQuery} from "../../store/backend-api/backend.api";
import {DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER} from "../../model/filters/DefaultFilters";

export function AdministrativeRolesFormCreate() {
    const {data: productionStages, isSuccess} = useSearchProductionStagesGuidePageQuery({...DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER, pageSize: 1000})

    return (<>
        {isSuccess && <AdministrativeRoleFormTemplate productionStages={productionStages.rows}/>}
    </>)
}