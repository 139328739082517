import React from "react";
import {AdministrativeRole} from "../../model/administrative-roles/AdministrativeRole";
import {AdministrativeRoleFormTemplate} from "./AdministrativeRoleFormTemplate";
import {useSearchProductionStagesGuidePageQuery} from "../../store/backend-api/backend.api";
import {DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER} from "../../model/filters/DefaultFilters";

interface FormProps{
    data: AdministrativeRole
}

export function AdministrativeRolesFormEdit(props: FormProps) {
    const {data: productionStages, isSuccess} = useSearchProductionStagesGuidePageQuery({...DEFAULT_PRODUCTION_STAGE_GUIDE_FILTER, pageSize: 1000})

    return (<>
        {isSuccess && <AdministrativeRoleFormTemplate productionStages={productionStages.rows} data={props.data}/>}
    </>)
}