import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {clearData} from "../slices/data.slice";
import {TableFilterKeys} from "../../model/filters/TableFiltersDefinitions";
import {updateFilterPage} from "../slices/filters.slice";

export const ClearCacheMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (action.type.endsWith('/fulfilled') && action.meta?.arg?.type === 'mutation') {
        const url: string = action.meta.baseQueryMeta.request.url
        const method: string = action.meta.baseQueryMeta.request.method
        const dataType: null | TableFilterKeys[] = getDataType(url, method)
        if (dataType) {
            dataType.forEach(type => {
                api.dispatch(clearData(type))
                api.dispatch(updateFilterPage({key: type, value: 0}))
            })
        }
    }
    return next(action)
}

function getDataType(url: string, method: string) {
    if (url.includes("guide")) {
        return null
    }
    if (url.includes("nomenclature") && !url.includes("attachment")) {
        return [TableFilterKeys.NOMENCLATURE_GUIDE_FILTER]
    }
    if (url.includes("measurement-unit")) {
        return [TableFilterKeys.MEASUREMENT_UNIT_GUIDE_FILTER]
    }
    if (url.includes("role")) {
        return [TableFilterKeys.ADMINISTRATIVE_ROLE_GUIDE_FILTER]
    }
    if (url.includes("user")) {
        return [TableFilterKeys.USER_GUIDE_FILTER]
    }
    if (url.includes("individual") && !url.includes("photo")) {
        return [TableFilterKeys.INDIVIDUALS_GUIDE_FILTER]
    }
    if (url.includes("bill-of-materials") && !url.includes("/material") && !url.includes("stage") && !url.includes("step")) {
        if (url.includes("product")) {
            if (method === 'PUT') {
                return [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]
            } else {
                return null
            }
        }
        return [TableFilterKeys.TECHNOLOGICAL_PROCESS_CHART_GUIDE_FILTER]
    }
    if (url.includes("production-stage")) {
        return [TableFilterKeys.PRODUCTION_STAGE_GUIDE_FILTER]
    }
    if (url.includes("technological-operation")) {
        return [TableFilterKeys.TECHNOLOGICAL_OPERATION_GUIDE_FILTER]
    }
    if (url.includes("production-order")) {
        return [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER, TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]
    }
    if (url.includes("production-task")) {
        return [TableFilterKeys.PRODUCTION_ORDER_EXECUTION_JOURNAL_FILTER, TableFilterKeys.PRODUCTION_ORDER_JOURNAL_FILTER, TableFilterKeys.PRODUCTION_ORDER_ANALYSIS_BY_TIME_FILTER]
    }
    if (url.includes("zone")) {
        return [TableFilterKeys.ADMINISTRATIVE_AND_FUNCTIONAL_ZONE_GUIDE_FILTER]
    }
    return null
}